import { checkExhausted } from "@cartographerio/util";
import classNames from "classnames";
import type { ReactNode } from "react";
import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { IoChevronDownSharp } from "react-icons/io5";
import Link from "./Link.js";
import "./Navbar.css";
import logo from "./NavbarLogo.png";

interface NavMenu {
  type: "NavMenu";
  label: string;
  items: NavItem[];
}

interface NavItem {
  type: "NavItem";
  label: string;
  to: string;
}

function navMenu(label: string, items: NavItem[]): NavMenu {
  return { type: "NavMenu", label, items };
}

function navItem(label: string, to: string): NavItem {
  return { type: "NavItem", label, to };
}

const navbarContent: (NavMenu | NavItem)[] = [
  navItem("Home", "https://modularriversurvey.org"),
  navMenu("Citizen Science", [
    navItem(
      "Modular River Survey Citizen Science",
      "https://modularriversurvey.org/morph-citizen-science"
    ),
    navItem("MoRPh Rivers", "https://modularriversurvey.org/morph-rivers"),
    navItem(
      "MoRPh Estuaries",
      "https://modularriversurvey.org/morph-estuaries"
    ),
    navItem("Mud Spotter", "https://modularriversurvey.org/mud-spotter"),
    navItem(
      "Getting Involved",
      "https://modularriversurvey.org/getting-involved"
    ),
    navItem(
      "Citizen Science Training",
      "https://modularriversurvey.org/citizen-science-training"
    ),
    navItem("Documentation", "https://docs.modularriversurvey.org/csci"),
  ]),
  navMenu("Professional", [
    navItem(
      "River Condition Assessment",
      "https://modularriversurvey.org/river-condition"
    ),
    navItem(
      "Professional Training",
      "https://modularriversurvey.org/professional-training"
    ),
    navItem("Documentation", "https://docs.modularriversurvey.org/pro"),
  ]),
  navItem("Sign In", "https://app.cartographer.io/signin"),
];

export default function Navbar() {
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <nav className="navbar-wrapper">
      <Drawer open={drawerOpen} onClose={() => setDrawerOpen(false)} />

      <div className="navbar-top">
        <button
          onClick={() => setDrawerOpen(true)}
          aria-label="Open Navigation Menu"
          className="navbar-open-drawer-button"
        >
          <AiOutlineMenu />
        </button>

        <h1 className="navbar-responsive-title">Modular River Survey</h1>

        <img className="navbar-logo" src={logo} alt="Modular River Survey" />

        <div className="flex-grow"></div>

        <ul className="navbar-menu">
          {navbarContent.map((item, index) => (
            <NavbarMenuItem key={index} item={item} />
          ))}
        </ul>
      </div>
    </nav>
  );
}

interface MenuItemProps {
  item: NavMenu | NavItem;
}

function NavbarMenuItem(props: MenuItemProps) {
  const { item } = props;

  const [open, setOpen] = useState(false);

  switch (item.type) {
    case "NavMenu":
      return (
        <li
          className="navbar-menu-item"
          onMouseOver={() => setOpen(true)}
          onMouseOut={() => setOpen(false)}
        >
          <button type="button">
            <span>{item.label}</span>
            <span className="h-3">
              <IoChevronDownSharp className="h-3" />
            </span>
          </button>

          {open && (
            <ul className="navbar-submenu">
              {item.items.map((item, index) => (
                <li key={index} className="navbar-submenu-item">
                  <Link.Smart to={item.to}>{item.label}</Link.Smart>
                </li>
              ))}
            </ul>
          )}
        </li>
      );

    case "NavItem": {
      return (
        <li className="navbar-menu-item">
          <Link.Smart to={item.to}>{item.label}</Link.Smart>
        </li>
      );
    }
    default:
      return checkExhausted(item);
  }
}

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

function Drawer(props: DrawerProps) {
  const { open, onClose } = props;

  return (
    <div
      className={classNames("navbar-drawer-wrapper", open ? "z-30" : "hidden")}
    >
      <DimmedBackground onClick={onClose}>
        <div className="navbar-drawer">
          <div className="navbar-title-wrapper">
            <h4 className="navbar-title">Modular River Survey</h4>
            <button
              className="navbar-close-drawer-button"
              aria-label="Close Navigation Menu"
              onClick={onClose}
            >
              <AiOutlineClose />
            </button>
          </div>

          <ul className="navbar-menu">
            {navbarContent.map((item, index) => (
              <DrawerMenuItem key={index} item={item} />
            ))}
          </ul>
        </div>
      </DimmedBackground>
    </div>
  );
}

function DrawerMenuItem(props: MenuItemProps) {
  const { item } = props;

  switch (item.type) {
    case "NavMenu":
      return (
        <li className="navbar-menu-item">
          <div className="label">{item.label}</div>
          <ul className="navbar-submenu">
            {item.items.map((item, index) => (
              <li key={index} className="navbar-submenu-item">
                <Link.Smart to={item.to}>{item.label}</Link.Smart>
              </li>
            ))}
          </ul>
        </li>
      );

    case "NavItem": {
      return (
        <li className="navbar-menu-item">
          <Link.Smart to={item.to}>{item.label}</Link.Smart>
        </li>
      );
    }
    default:
      return checkExhausted(item);
  }
}

interface DimmedBackgroundProps {
  children?: ReactNode;
  onClick: () => void;
}

function DimmedBackground(props: DimmedBackgroundProps) {
  const { children, onClick } = props;

  return (
    <div className="fixed inset-0">
      <div
        onClick={onClick}
        className="absolute inset-0 bg-slate-900 opacity-50 fade-in-slow"
      ></div>
      <div className="absolute h-full z-10">{children}</div>
    </div>
  );
}
