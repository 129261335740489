import Link from "./Link.js";
import Spaced from "./Spaced.js";
import "./Footer.css";
import { Container } from "./Container.js";

export default function SiteFooter() {
  return (
    <Container width="default" className="footer">
      <Spaced spacing="2">
        <hr />

        <p>
          &copy; 2016-Present Modular River Survey Team. All rights reserved.
        </p>
        <p>
          Website and training courses run by{" "}
          <Link.External to="https://cartographer.io/company">
            Cartographer Studios
          </Link.External>
          .
        </p>
        <p>
          <Link.External to="https://cartographer.io/legal/website-terms">
            Website Terms
          </Link.External>
          {" | "}
          <Link.External to="https://cartographer.io/legal/privacy-policy=">
            Privacy Policy
          </Link.External>
          {" | "}
          <Link.External to="https://cartographer.io/legal/training-terms">
            Training Terms
          </Link.External>
        </p>
      </Spaced>
    </Container>
  );
}
