import type { LinksFunction, LoaderFunctionArgs } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import isError from "lodash/isError.js";
import appStyles from "./app.css?url";
import ButtonLink from "./components/ButtonLink.js";
import Navbar from "./components/Navbar.js";
import SiteFooter from "./components/SiteFooter.js";
import { HStack, VStack } from "./components/Stack.js";
import { authenticator } from "./services/auth/index.server.js";
import Link from "./components/Link.js";
import * as Sentry from "@sentry/remix";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: appStyles },
];

export default function App() {
  const { user } = useLoaderData<typeof loader>();

  Sentry.setUser(user == null ? null : { email: user.email, type: user.type });

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Modular River Survey</title>
        <Meta />
        <Links />
        <script
          src="https://cdn.usefathom.com/script.js"
          data-site="RLHFTYTM"
          defer
        ></script>
      </head>

      <body>
        <Navbar />
        <Outlet />

        <SiteFooter />

        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export async function loader({ request }: LoaderFunctionArgs) {
  const user = await authenticator.isAuthenticated(request);

  return json({ user });
}

export function ErrorBoundary() {
  const error = useRouteError();

  captureRemixErrorBoundaryError(error);
  console.error(error);

  const title = isRouteErrorResponse(error)
    ? `${error.status} ${error.statusText}`
    : isError(error)
      ? error.message
      : "Unknown Error";

  return (
    <html lang="en">
      <head>
        <title>{title}</title>
        <Meta />
        <Links />
      </head>
      <body>
        <div className="flex w-screen h-screen justify-center items-center">
          <div className="max-w-[50ch] leading-loose text-lg">
            {isRouteErrorResponse(error) ? (
              <NotFoundMessage error={error} />
            ) : (
              <InternalErrorMessage error={error} />
            )}
          </div>
        </div>
        <Scripts />
      </body>
    </html>
  );
}

function NotFoundMessage({ error }: { error: ErrorResponse }) {
  return (
    <VStack gap="4">
      <h1 className="text-3xl my-2">
        {error.status} {error.statusText}
      </h1>
      <p>Sorry! This page doesn&apos;t exist.</p>
      <p>Please try returning to the home page and navigating from there.</p>
      <HStack gap="2" justify="start" className="my-4">
        <ButtonLink.Internal to="/">Return Home</ButtonLink.Internal>
      </HStack>
    </VStack>
  );
}

function InternalErrorMessage({ error }: { error: unknown }) {
  const title = isError(error) ? error.message : "Unknown Error";

  return (
    <VStack gap="4">
      <h1 className="text-3xl my-2">{title}</h1>
      <p>Something went wrong! Sorry for the inconvenience.</p>
      <p>
        Please try again in a couple of minutes. If the problem persists, please
        consider reporting it to{" "}
        <Link.External to="https://cartographer.io">Cartographer</Link.External>{" "}
        Support below:
      </p>
      <HStack gap="2" justify="start" className="my-4">
        <ButtonLink.Internal to="/">Return Home</ButtonLink.Internal>
        <ButtonLink.External to="https://help.cartographer.io/contact">
          Report Problem
        </ButtonLink.External>
      </HStack>
    </VStack>
  );
}
